import React, { useEffect, useState } from 'react'
import accordionDown from './../../../assets/pre-foreclosure/down.png';
import { Button, Col, Image, Row } from 'react-bootstrap';

const PreClosureFaq = ({ heading, lists }) => {

    const [faqs, setFaqs] = useState([]);
    const [expend, setExpend] = useState(false)
    const [active, setActive] = useState(0);

    useEffect(() => {
        handleFilter();
    }, [expend]);

    const handleFilter = () => {
        if (expend) {
            setFaqs(lists);
        } else {
            setFaqs(lists?.filter((item, i) => i <= 4 ? item : ''));
        }
    }

    return (
        <>
            <div className='accordion-slide'>
                <Row>
                    <Col xs={12} md={12} lg={10} xl={8} className='mx-auto'>
                        <h1 className="title my-md-5">{heading}</h1>
                    </Col>
                </Row>
                <Row className='justify-content-center'>
                    <Col xl={12} className='mb-4'>
                        {faqs?.map((list, index) =>
                            <div className='cont-accordion mb-4' key={index}>
                                <div className='heading-accordion hover'>
                                    <Row>
                                        <Col xs={11} sm={11} md={11} >
                                            <h6>{list?.attributes?.Questions}</h6>
                                        </Col>
                                        <Col xs={1} sm={1} md={1}>
                                            <Image
                                                id={"toggleAcc-" + index}
                                                className="icon-accordion"
                                                src={accordionDown}
                                                alt='icon'
                                            />
                                        </Col>
                                    </Row>
                                </div>
                                <div className={`para-accordion`} >
                                    <Row>
                                        <Col md={11} lg={11} xl={11}>
                                            <p className='mb-0'>{list?.attributes?.Answer}</p>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        )}
                    </Col>
                    <Col xl={4} className='mt-4'>
                        <Button className='outline-theme' onClick={() => setExpend(!expend)}>
                            See more Frequently Asked Questions
                        </Button>
                    </Col>
                </Row>
            </div>

        </>
    )
}

export default PreClosureFaq