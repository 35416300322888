import { memo } from "react";
import { Link, useLocation } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import {
  FaTiktok,
  FaLinkedin,
  FaFacebookF,
} from "react-icons/fa";
import { AiFillYoutube, AiFillCloseCircle } from "react-icons/ai";
import { BsInstagram } from "react-icons/bs";
import logoFooter from "../assets/images/f_logo.png";
import Xicon from "./Xicon";
import { STRAPI_IMAGE } from "../constants/strapi-api-constant";



const Footer = ({ content }) => {
  const { pathname } = useLocation();


  const socialLinks = [
    { title: "Facebook", icon: <FaFacebookF />, url: "https://www.facebook.com/skipifyai" },
    { title: "Instagram", icon: <BsInstagram />, url: "https://www.instagram.com/skipify.ai" },
    { title: "LinkedIn", icon: <FaLinkedin />, url: "https://www.linkedin.com/company/skipify-ai/" },
    { title: "YouTube", icon: <AiFillYoutube />, url: "https://www.youtube.com/channel/UCFMuLK0m8gHQhm76ro4xVgg" },
    { title: "Twitter", icon: <Xicon />, url: "https://twitter.com/Skipify_ai" },
    { title: "TikTok", icon: <FaTiktok />, url: "https://www.tiktok.com/@skipify.ai" },
  ];

  const links = [
    { name: "Affiliate", path: "/affiliate" },
    { name: "About Us", path: "/about-us" },
    { name: "Contact Us", path: "/contact-us" },
    { name: "Support", path: "/contact-us" },
    { name: "Privacy Policy", path: "/privacy-policy" },
    { name: "Terms of Service", path: "/terms" },
    { name: "Plans", path: "/pricing-plan" },
    { name: "Contact Sales", path: "/contact-sales" },
  ];
  const handleScreenTop = () => {
    window.scrollTo(0, 0);
  }

  return (
    <footer className="footer">
      <div className="footer_div container">

        <Row className="mt-5">
          <Col xs={12} sm={6} lg={4}>
            <Link to="/">
              {content?.FooterImage ? <img src={STRAPI_IMAGE + content?.FooterImage?.url} alt={content?.FooterImage?.name} width={content?.FooterLogoWidth} loading="lazy" /> : <img src={logoFooter} alt="Skipify.ai logo" width="200" loading="lazy" />}
            </Link>
            <p className="logoText">Lead Generation. Made Easy</p>
            <div className="socialLinks">
              <ul>
                {socialLinks.map(({ title, icon, url }) => (
                  <li key={title}>
                    <a href={url} target="_blank" rel="noreferrer" title={`Skipify.ai ${title}`}>
                      {icon}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </Col>

          <Col xs={12} sm={6} lg={8}>
            <Row>
              {["Company", "Resources", "Pricing"].map((section, index) => (
                <Col xs={12} sm={4} key={section}>
                  <div className="footerColumn link">
                    <h4>{section}</h4>
                    <ul>
                      {links
                        .slice(index * 3, (index + 1) * 3)
                        .map(({ name, path }) => (
                          <li key={name}>
                            {pathname === path ? (
                              // eslint-disable-next-line jsx-a11y/anchor-is-valid
                              <a onClick={() => handleScreenTop()} className="active">{name}</a>
                            ) : (
                              <Link to={path}>{name}</Link>
                            )}
                          </li>
                        ))}
                    </ul>
                  </div>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col xs={12} sm={10} className="d-flex justify-content-between align-items-center">
            <ul className="footerLinks-copy">
              <li>
                <span>© {new Date().getFullYear()} <a href="https://skipify.ai/">Skipify.ai</a> | All Rights Reserved.</span>
              </li>
              <li>
                <span>Powered by</span>
                <a href="https://www.plego.com/" target="_blank" className="powerdby" rel="noreferrer">Plego Technologies</a>
              </li>
            </ul>
          </Col>
          <Col xs={12} sm={2} className="d-flex justify-md-content-center align-items-center">
            <a href="https://www.plego.com/" target="_blank" className="powerdby" rel="noreferrer">
              <img src={require("./../assets/images/plego-white.png")} alt="Plego Technologies" className="img-fluid" loading="lazy" />
            </a>
          </Col>
        </Row>
      </div>
    </footer>
  );
};

export default memo(Footer);
