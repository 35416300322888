import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { LazyLoadImage } from "react-lazy-load-image-component";
import { STRAPI_IMAGE } from '../../../constants/strapi-api-constant';
const PreForeClosure = ({ data }) => {
    const [lists, setLists] = useState([]);
    const [heading, setHeading] = useState();
    useEffect(() => {
        data?.Description?.map((item) => {
            if (item?.type === "list") {
                setLists(item?.children);
            } else if (item?.type === "heading") {
                setHeading(item?.children?.length > 0 ? item?.children[0].text : '');
            }
        })
    }, [data]);


    return (
        <>
            <div className="container trust-by gray-theme">
                <div className="row justify-context-center mb-4">
                    <div className="col-md-12 col-lg-5 align-self-center height-auto">
                        <h1 className='text-gray800'>{data?.Heading}</h1>
                    </div>
                    <div className="col-md-12 col-lg-7 bd-l-grey ps-lg-4">
                        <h2 className='mb-3 ps-lg-4'>
                            {data?.Paragraph}
                        </h2>
                    </div>
                </div>

                <div className="row justify-context-center py-4">
                    {data?.SectionType === "LeftToRight" ?
                        <>
                            <div className="col-lg-6 align-self-center col-md-12">
                                <h4 className='description mb-4'>{heading}</h4>
                                <ul className="maximize-list">
                                    {lists.map((list, l) => <>
                                        <li key={l}>

                                            {list?.children?.length > 1 ?

                                                <>
                                                    <span className="orange">{l + 1}</span>
                                                    <div className='maximize-list-div'>
                                                        <h6 >{list?.children[0]?.text}</h6>
                                                        <div
                                                            className='innerDiv'
                                                            dangerouslySetInnerHTML={{ __html: list?.children[1]?.text }}
                                                        />
                                                    </div>
                                                </>
                                                :
                                                <>
                                                    <span className="orange">{l + 1}</span>
                                                    <div className='maximize-list-div'>
                                                        <h6 >{list?.children[0]?.text}</h6>

                                                    </div>
                                                </>

                                            }
                                        </li>
                                    </>)}
                                </ul>
                            </div>
                            <div className="col-lg-6 align-self-center col-md-12">
                                <Row>
                                    <Col>
                                        <LazyLoadImage
                                            src={STRAPI_IMAGE + data?.Image?.data?.attributes?.url}
                                            effect="blur"
                                            className='img-fluid'
                                            alt="Foreclosure Data"
                                        />
                                    </Col>
                                </Row>
                            </div>
                        </>
                        :
                        <>
                            <div className='col-12'>
                                <h4 className='text-dark text-gray800 mb-4'>{heading}</h4>
                            </div>
                            <div className="col-lg-6 align-self-center col-md-12">
                                <Row>
                                    <Col>
                                        <LazyLoadImage
                                            src={STRAPI_IMAGE + data?.Image?.data?.attributes?.url}
                                            effect="blur"
                                            className='img-fluid'
                                            alt="Foreclosure Data"
                                        />
                                    </Col>
                                </Row>
                            </div>

                            <div className="col-lg-6 align-self-center col-md-12">
                                <ul className="maximize-list">
                                    {lists.map((list, l) => <>
                                        <li key={l}>

                                            {list?.children?.length > 1 ?

                                                <>
                                                    <span className="orange">{l + 1}</span>
                                                    <div className='maximize-list-div'>
                                                        <h6 >{list?.children[0]?.text}</h6>
                                                        <div
                                                            className='innerDiv'
                                                            dangerouslySetInnerHTML={{ __html: list?.children[1]?.text }}
                                                        />
                                                    </div>
                                                </>
                                                :
                                                <>
                                                    <span className="orange">{l + 1}</span>
                                                    <div className='maximize-list-div'>
                                                        <h6 >{list?.children[0]?.text}</h6>

                                                    </div>
                                                </>

                                            }
                                        </li>
                                    </>)}
                                </ul>
                            </div>
                        </>
                    }


                </div>
            </div>

            <div className="sep-vertical"></div>
        </>
    )
}

export default PreForeClosure