import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
const PowerOfPredictive = ({ data }) => {
    return (
        <div className='paddingSection'>
            <Row className="justify-context-center">
                <Col xs={12} md={6} lg={6} className='mx-auto text-center'>
                    <span className="label-title ">INTRO</span>
                </Col>
            </Row>
            <Row className=" justify-content-center">
                <Col xs={12} sm={12} md={12} lg={8}>
                    <h1 className="title my-4">{data?.Heading}</h1>
                </Col>
            </Row>

            <Container className="trust-by gray-theme">
                <Row className="justify-context-center">
                    <Col>
                        {data?.VideoURL &&
                            <iframe src={data?.VideoURL} title="How to Access Pre-Foreclosure &amp; Likely-to-Default (Propensity Data) on Skipify.ai" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                        }
                    </Col>
                    <Col md={12} lg={5} className="align-self-center">
                        {data?.Description.map((item, i) => {
                            if (item?.type === "paragraph") {
                                return <h3 className='mb-3 ps-lg-4 powerTitle' key={i}>{item?.children[0]?.text}</h3>
                            }
                        })}
                    </Col>
                </Row>
            </Container>

        </div>
    )
}

export default PowerOfPredictive