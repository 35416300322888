import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import ReactPlayer from "react-player/lazy";
import {
    StripeAxios,
    SKIPIFY_HOME_ONEPAGE,
} from "../../constants/strapi-api-constant";



import HeroSection from "./sections/HeroSection";
import VideoSection from "./sections/VideoSection";
import FeatureSection from "./../../components/FeatureSection";
import RealEstateExport from "./sections/RealEstateExport";
import TestimonialSection from "./sections/TestimonialSection";
import BlogSection from "./sections/BlogSection";
import MiniSection from "./sections/MiniSection";
import WhyWeStand from "./sections/WhyWeStand";
import LeadGeneration from "./sections/LeadGeneration";
import Seo from "./../../components/Seo";
import NewsLetter from "./../../components/NewsLetter";

const NewHomepage = () => {
    const [show, setShow] = useState(false);
    const [videoUrl, setVideoUrl] = useState("");
    const [content, setContent] = useState({});

    useEffect(() => {
        fetchHomePageData();
    }, []);

    const fetchHomePageData = async () => {
        try {
            const res = await StripeAxios.get(SKIPIFY_HOME_ONEPAGE);
            if (res?.data) {
                setContent(res.data);
            }
        } catch (error) {
            console.error("Error fetching homepage data:", error);
        }
    };

    const handleClose = () => {
        setShow(false);
        setVideoUrl("");
    };

    const handleShow = (url) => {
        setVideoUrl(url);
        setShow(true);
    };



    const getYouTubeThumbnail = (url) => {
        const videoId = url?.slice(17);
        return `https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`;
    };

    return (
        <div className="container ai-power-dashboard">
            <Seo metaData={content?.Seo} />

            <Modal
                show={show}
                onHide={handleClose}
                className="videoModal"
                centered
                size="xl"
            >
                <Modal.Header closeButton aria-label="Close Video"></Modal.Header>
                <Modal.Body>
                    {videoUrl && (
                        <ReactPlayer
                            className="mega-iframe"
                            light={getYouTubeThumbnail(videoUrl)}
                            controls
                            url={videoUrl}
                            playing={show}
                        />
                    )}
                </Modal.Body>
            </Modal>

            <HeroSection handleShow={handleShow} data={content?.HeroSection} url={content?.VideoSection?.VideoLink} />
            <VideoSection
                data={content?.VideoSection}
                getYouTubeThumbnail={getYouTubeThumbnail}
            />
            <FeatureSection
                data={{
                    Heading: content?.FeatureSectionHeading,
                    Label: content?.FeatureSectionLabel,
                    Features: content?.Features,
                }}
            />
            <RealEstateExport
                data={content?.RealEstateSection}
                getYouTubeThumbnail={getYouTubeThumbnail}
            />
            <LeadGeneration content={content} />
            <WhyWeStand content={content} />
            <TestimonialSection
                data={{
                    Heading: content?.TestimonailHeading,
                    testimonials: content?.testimonials,
                }}
            />
            <MiniSection handleShow={handleShow} content={content} />
            <BlogSection content={content} />
            <NewsLetter heading={content?.NewsLetterHeading} />
        </div>
    );
};

export default NewHomepage;
