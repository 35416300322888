import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import ReactPlayer from 'react-player'
import { handleViewDescription } from '../../../utils/CommonView'

const RealEstateExport = ({ data, getYouTubeThumbnail }) => {
    return (
        <Container className="trust-by paddingSection">
            <Row className="justify-context-center">
                <Col md={12} lg={6} className="bd-r-grey">
                    <h1>{data?.Heading}</h1>
                </Col>
                <Col md={12} lg={6}>
                    {handleViewDescription(data?.Description)}
                </Col>
            </Row>
            <div className="row justify-context-center">
                <div className="col mt-4">
                    <ReactPlayer height={"600px"} className='mega-iframe' light={getYouTubeThumbnail(data?.VideoLink)} controls url={data?.VideoLink} playing={true} />
                </div>
            </div>
        </Container>
    )
}

export default RealEstateExport