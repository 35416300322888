import { AiOutlineDownload } from "react-icons/ai";
import React from 'react'
import { STRAPI_IMAGE } from '../constants/strapi-api-constant'
import { Col, Row } from 'react-bootstrap'
import { Card, Button } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import downloadBanner from '../../src/assets/images/download-banner.png'

const FreeDownloadItems = ({ downloadItem }) => {
  const history = useNavigate()
  const handleRedirect = () => {
    history(`/free-download-form/${downloadItem.attributes?.slug}`)
  }
  return (
    <Card className='blog-card downloadItem border-0' onClick={() => handleRedirect()}>
      <div className="ovarlay"></div>

      <Row>
        <Col md={7}>
          <Card.Img variant="top" src={STRAPI_IMAGE + downloadItem?.attributes?.ItemImage?.data?.attributes?.url} />
        </Col>
      </Row>
      <Card.Body className="d-flex justify-content-between align-items-center">
        <Row>
          <Col md={12}>
            <h4>{downloadItem.attributes?.Label}</h4>
          </Col>
          <Col md={12}>
            <h5>{downloadItem.attributes?.ItemTitle}</h5>
          </Col>
        </Row>
      </Card.Body>
      <button className="download-button">
        {downloadItem.attributes?.DownloadButtonText}
        <AiOutlineDownload color="#EFFAFE" size={35} cursor="pointer" onClick={() => handleRedirect()} />
      </button>
    </Card>
  )
}

export default FreeDownloadItems