import { Col, Image, Row } from "react-bootstrap";

export const handleViewDescription = (Description) => {
    return Description?.map((des, d) => {

        const { type, children, level, format, image } = des;
        const renderChildren = (childrenArray) =>
            childrenArray?.map((child, index) => (
                child?.type === 'text' && !child?.code && !child?.bold && !child?.underline && !child?.italic && !child?.strikethrough
                    ? child?.text
                    : child?.bold ? <strong key={index}>{child?.text}</strong>
                        : child?.type == "link" ? <a href={child?.url} target="_blank" key={index} className="des_link">{child?.children[0]?.text}</a>
                            : child?.underline ? <u key={index}>{child?.text}</u>
                                : child?.italic ? <i key={index}>{child?.text}</i>
                                    : child?.strikethrough ? <del key={index}>{child?.text}</del>
                                        : <br key={index} />
            ));

        if (type === "paragraph") {
            return (
                <p key={d}>
                    {renderChildren(children)}
                </p>
            );
        }
        if (type === "quote") {
            return (
                <p key={d} className="quote">
                    {'"'}
                    {renderChildren(children)}
                    {'"'}
                </p>
            );
        }

        if (type === "heading") {
            const HeadingTag = `h${level}`;
            return (
                <HeadingTag key={d}>
                    {children[0]?.text}
                </HeadingTag>
            );
        }

        if (type === "list") {

            const ListTag = format === "ordered" ? 'ol' : 'ul';
            return (
                <ListTag className="blogsList" key={d}>
                    {children?.map((item, li) => {
                        const { indentLevel, children: itemChildren } = item;
                        if (itemChildren?.length > 1) {
                            return indentLevel !== 1 ? (
                                <li key={li}>
                                    {renderChildren(itemChildren)}
                                </li>
                            ) : (
                                <ul type="circle" key={`${d}-${li}`}>
                                    {itemChildren?.map((subList, sl) => (
                                        <li key={`${d}-${li}-${sl}`}>
                                            {subList?.children[0]?.text}
                                        </li>
                                    ))}
                                </ul>
                            );
                        } else {
                            return (
                                <li key={li}>
                                    {itemChildren[0]?.text}
                                </li>
                            );
                        }
                    })}
                </ListTag>
            );
        }

        if (type === "image") {
            return (
                <Row key={d} className="mb-5">
                    <Col xs={12} sm={12} md={12} xxl={10} className='mx-auto'>
                        <Image src={image?.url} alt="blog" className='img-fluid rounded-5' />
                    </Col>
                </Row>
            )
        }
        return null;
    });
}