import React, { useEffect, useState } from 'react'
import { NEW_NEWSLETTER, StripeAxios } from '../constants/strapi-api-constant';
import { Col, Container, Modal, Row, Spinner } from 'react-bootstrap';
import { AiFillCloseCircle } from 'react-icons/ai';
import { SUBMIT_NEWSLETTER_SUBSCRIBE } from '../constants/api-routes.constant';
import { IoIosArrowRoundForward } from 'react-icons/io';


const NewsLetter = ({ heading }) => {

    const EmailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

    // Subcribtion
    const [email, setEmail] = useState("");
    const [emailErr, setEmailErr] = useState("");
    const [load, setLoad] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");
    const [queryErr, setqueryErr] = useState("");

    // Modal
    const [show, setShow] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        setqueryErr("");
        setLoad(true);
        let flag = false;
        if (!email) {
            setEmailErr("Please Enter Email Address");
            setLoad(false);
            flag = true;
        } else if (!EmailRegex.test(email)) {
            setEmailErr("Enter a valid email address");
            setLoad(false);
            flag = true;
        } else {
            setEmailErr("");
        }

        if (!flag) {
            fetch(SUBMIT_NEWSLETTER_SUBSCRIBE, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    email: email,
                }),
            })
                .then((response) => {
                    if (response.ok) {
                        return response.json();
                    }
                    setqueryErr("Error: Invalid Username or Password provided.");
                })
                .then((response) => {
                    if (response && response?.statusCode === 200) {
                        setLoad(false);
                        setSuccessMessage("Subscribed Successfully");
                        setShow(true);
                        setTimeout(() => {
                            setSuccessMessage("");
                            setShow(false);
                        }, 5000);
                        setEmail("");
                    }
                })
                .catch((e) => {
                    setLoad(false);
                    setqueryErr("Error: Invalid Username or Password provided.");
                });
        }
    };

    const handleClose = () => setShow(false);

    const ShowModalMessage = () => {
        return (
            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                centered
                className="glassModel"
            >
                <Modal.Body>
                    <Row className="d-flex justify-content-end mb-2">
                        <Col md={1}>
                            <AiFillCloseCircle onClick={handleClose} cursor={"pointer"} />
                        </Col>
                    </Row>
                    <div className="newsletter_message_modal">
                        <h3>Thank You</h3>
                        <h1>For subscribing to the Skipify.ai Newsletter!</h1>
                    </div>
                </Modal.Body>
            </Modal>
        );
    };


    return (
        <Container className="newsletter my-5">
            <Row className="justify-content-center">
                <Col xs={12} md={6} className="align-self-center ">
                    <h1>{heading}</h1>
                </Col>

                <Col xs={12} md={12} lg={6}>
                    <Row>
                        <Col md={8} sm={12}>
                            <input placeholder="Enter Email Address" className="input-search-place"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />

                        </Col>


                        <Col xs={12} md={4} className=" align-self-center ">
                            <div className="actions">
                                <button className="primary" onClick={handleSubmit}>
                                    {load ? <Spinner animation="border" size="sm" /> : 'Subscribe'}
                                </button>

                            </div>
                        </Col>
                        <Col md={12}>
                            {successMessage && (
                                <span className="text-success">{successMessage}</span>
                            )}
                            {queryErr ||
                                (emailErr && (
                                    <span className="text-danger mx-3">
                                        {queryErr} {emailErr}
                                    </span>
                                ))}
                        </Col>
                    </Row>

                </Col>

            </Row>
            {ShowModalMessage()}
        </Container>
    )
}

export default NewsLetter