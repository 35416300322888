import { Modal } from 'react-bootstrap';
import React, { useEffect, useState } from "react";

import {
    StripeAxios,
    NEW_HELP_CENTER,
} from '../../constants/strapi-api-constant';


import HeroSection from "./section/HeroSection";
import PowerOfPredictive from "./section/PowerOfPredictive";
import HelpCenterSection from "./section/SurplusFunds";
import PreClosureFaq from "./section/PreClosureFaq";
import FeatureSection from "../../components/FeatureSection";
import NewsLetter from "./section/Subscription";
import Seo from "../../components/Seo";
import Loader from "../../components/Loader";


const SurplusFunds = () => {
    const [show, setShow] = useState(false);
    const [content, setContent] = useState({});
    const [loading, setLoading] = useState(false)

    const handleClose = () => { setShow(false); };
    const handleShow = () => { setShow(true) };

    const preClosureSection = async () => {
        try {
            setLoading(true)
            const res = await StripeAxios.get(NEW_HELP_CENTER);
            if (res) {
                const { data } = res.data;
                setContent(data?.attributes);
            }
        } catch (error) {
            console.log("PreClosure Page =>", error);
        } finally {
            setLoading(false)
        }

    }

    useEffect(() => {
        preClosureSection();
    }, []);

    return (
        <div className="container ai-power-dashboard surplus-funds">

            {loading ? <Loader /> :
                <>
                    <Seo metaData={content?.SEO} />
                    <Modal show={show} onHide={handleClose} className='videoModal' centered size="xl">
                        <Modal.Header closeButton></Modal.Header>
                        <Modal.Body>
                            <iframe className='mega-iframe' src="https://www.youtube.com/embed/aKzRVyO_gfI" title="The Only Skiptracing Software You Need For Surplus Funds" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                        </Modal.Body>
                    </Modal>

                    <HeroSection data={content?.HeroSectionHelpCenter} handleShow={handleShow} />

                    <PowerOfPredictive data={content?.HeroDescriptionWithVideo} />

                    <HelpCenterSection data={content?.HelpCenterSection} />
                    <HelpCenterSection data={content?.HelpCenterSection2} />
                    <FeatureSection data={{
                        Heading: content?.FeatureSectionHeading,
                        Label: 'Features',
                        Features: content?.Features,
                    }} />

                    <PreClosureFaq heading={content?.AccordionSectionHeading} lists={content?.helpcenter_faqs?.data} />
                    <NewsLetter data={content?.HelpCenterSubscription} />
                </>
            }

        </div>
    )
}

export default SurplusFunds