import React from 'react'
import { Col, Row } from 'react-bootstrap'
import TestimonialItem from '../../../components/TestimonialItem'

const TestimonialSection = ({ data }) => {
    return (
        <div className='paddingSection'>
            <Row className="justify-content-center optimize-sec">
                <Col lg={8} md={12} className="align-self-center mb-4">
                    {data?.Heading?.length > 0 &&
                        <h1 className="left-sec text-center my-4">{data?.Heading[0]?.children[0]?.text} <span>{data?.Heading[0]?.children[1]?.text}</span>
                            {data?.Heading[0]?.children[2]?.text}
                        </h1>
                    }
                </Col>
            </Row>
            <Row className="justify-content-center testimony-ai-power">
                {data?.testimonials?.map((list, ts) => (
                    <Col xs={12} md={6} lg={4} className="mb-4" key={ts}>
                        <TestimonialItem testimonial={list} />
                    </Col>
                ))}
            </Row>
        </div>

    )
}

export default TestimonialSection