import React, { useState } from 'react'
import { Spinner, Form, Alert, Row, Col, Button, Container } from "react-bootstrap";
import { AFFILIAT_APPLY_FORM } from '../../../constants/api-routes.constant';


const AffiliateForm = ({ content }) => {

  const [phoneNumberWithMaking, setPhoneNumberWithMaking] = useState();
  const [loader, setLoader] = useState(false);
  const [resErrMessage, setResErrMessage] = useState('');
  const [resMessage, setMessage] = useState({ error: "", success: '' });

  const [formValues, setFormValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    companyName: "",
    businessType: "",
    website: "",
    socialUrl: ""
  });
  const [formError, setFormError] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    companyName: "",
    businessType: "",
    website: "",
    socialUrl: ""
  });


  const handleChange = (e) => {
    setResErrMessage('')
    setFormValues(formValues => ({ ...formValues, [e.target.name]: e.target.value }))
  }

  const EmailRegex = /^[A-Z0-9._+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoader(true);

    let errorFlag = false;
    if (formValues?.firstName == '') {
      setFormError(formError => ({ ...formError, firstName: "Please enter first name" }));
      setLoader(false);
      errorFlag = true;
    }
    if (formValues?.lastName == '') {
      setFormError(formError => ({ ...formError, lastName: "Please enter last name" }));
      setLoader(false);
      errorFlag = true;
    }
    if (formValues?.email?.trim() == '') {
      setFormError(formError => ({ ...formError, email: "Please enter email" }));
      setLoader(false);
      errorFlag = true;
    }
    if (!EmailRegex.test(formValues?.email)) {
      setFormError(formError => ({ ...formError, email: "Enter a valid email" }));
      setLoader(false);
      errorFlag = true;
    }
    if (formValues?.phoneNumber == '') {
      setFormError(formError => ({ ...formError, phoneNumber: "Please enter phone number" }));
      setLoader(false);
      errorFlag = true;
    }
    if (formValues?.companyName == '') {
      setFormError(formError => ({ ...formError, companyName: "Please enter company name" }));
      setLoader(false);
      errorFlag = true;
    }

    if (!errorFlag) {

      fetch(AFFILIAT_APPLY_FORM, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          firstName: formValues?.firstName,
          lastName: formValues?.lastName,
          email: formValues?.email,
          phoneNumber: formValues?.phoneNumber,
          companyName: formValues?.companyName,
          businessType: formValues?.businessType,
          website: formValues?.website,
          socialUrl: formValues?.socialUrl,

        }),
      }).then((response) => {
        return response.json();
      }).then((response) => {
        if (response && response?.statusCode === 200) {
          setLoader(false);
          setMessage(resMessage => ({ ...resMessage, success: (<>Thank you for applying to the <a href={process.env.REACT_APP_URL} className='success'>Skipify.ai</a> Affiliate Program! Next step instructions have been emailed to you at {formValues?.email}.If you run into any problems, please reach out to <a href='mailto:support@skipify.ai' className='success'>support@skipify.ai</a>.</>) }));
          setFormValues({
            firstName: "",
            lastName: "",
            email: "",
            phoneNumber: "",
            companyName: "",
            businessType: "",
            website: "",
            socialUrl: ""
          })
          setPhoneNumberWithMaking('')
        }
        if (response && response?.statusCode === 400) {
          setLoader(false);
          if (response?.message == 'user-already-registerd') {
            setMessage(resMessage => ({
              ...resMessage, error: (<>Looks like you already have a <a href={process.env.REACT_APP_URL} className='danger'>Skipify.ai</a> account! Please log in to your account to apply for the Affiliate Program.If you have any trouble, please reach out to <a href='mailto:support@skipify.ai' className='danger'> support@skipify.ai</a >.</>)
            }));
          } else {
            setMessage(resMessage => ({ ...resMessage, error: response?.message }));
          }
        }

      }).catch((e) => {
        setLoader(false);
        setMessage(resMessage => ({ ...resMessage, error: "Error: Api server issue" }));
      });

    }


  }





  // Function to remove the Masking from phone Number
  const NormalizePhoneNumber = (phone) => {
    const numericOnly = phone.replace(/\D/g, '');
    let maskedNumber = '';
    if (numericOnly.length > 0) {
      maskedNumber = `(${numericOnly.slice(0, 3)}`;
    }
    if (numericOnly.length > 3) {
      maskedNumber += `) ${numericOnly.slice(3, 6)}`;
    }
    if (numericOnly.length > 6) {
      maskedNumber += `-${numericOnly.slice(6, 10)}`;
    }
    setFormValues({
      ...formValues,
      phoneNumber: numericOnly.slice(0, 10),
    })
    setPhoneNumberWithMaking(maskedNumber);  // saving value for input to the previous value in input

  }
  return (
    <div className="affiliateForm" id="affialte">
      <Container>
        <Row>
          <Col xs={12} sm={12} md={12} lg={5} xl={5} className="mb-3">
            <h1 className='affiliateFormHead'>{content?.Heading}</h1>
            <p className='affiliateFormDes'>{content?.Description}</p>
          </Col>
          <Col xs={12} sm={12} md={12} lg={7} className="mx-auto">
            <div className="left-contact2">


              <form method="POST" onSubmit={handleSubmit} className='mt-xl-4'>
                <Row>
                  <Col sm={12} md={12} lg={12} xl={12}>
                    {resMessage?.error && <Alert variant="danger" dismissible>{resMessage?.error} </Alert>}
                    {resMessage?.success && <Alert variant="success" dismissible>{resMessage?.success} </Alert>}
                  </Col>
                  <Col sm={12} md={12} lg={6} xl={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>First Name *</Form.Label>
                      <Form.Control
                        type="text"
                        name="firstName"
                        id="first_name"
                        placeholder="John"
                        value={formValues?.firstName}
                        onChange={(e) => { handleChange(e); setFormError({ ...formError, firstName: "" }); }}
                      />
                      {formError?.firstName != '' && <span className="text-danger">{formError?.firstName}</span>}

                    </Form.Group>
                  </Col>
                  <Col sm={12} md={12} lg={6} xl={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>Last Name *</Form.Label>
                      <Form.Control
                        type="text"
                        name="lastName"
                        id="last_name"
                        placeholder="Doe"
                        value={formValues?.lastName}
                        onChange={(e) => { handleChange(e); setFormError({ ...formError, lastName: "" }); }}
                      />
                      {formError?.lastName && <span className="text-danger">{formError?.lastName}</span>}
                    </Form.Group>


                  </Col>
                  <Col sm={12} md={12} lg={6} xl={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>Email *</Form.Label>
                      <Form.Control
                        type="email"
                        name="email"
                        id="email_address"
                        placeholder="johndoe@email.com"
                        value={formValues?.email}

                        onChange={(e) => { handleChange(e); setFormError({ ...formError, email: "" }); }}
                      />
                      {formError?.email && <span className="text-danger">{formError?.email}</span>}
                    </Form.Group>


                  </Col>

                  <Col sm={12} md={12} lg={6} xl={6}>
                    <Form.Group className="mb-3" >
                      <Form.Label>Phone Number *</Form.Label>
                      <Form.Control
                        type="text"
                        name="phoneNumber"
                        onChange={(e) => { NormalizePhoneNumber(e.target.value.replace(/\D/g, '')); setFormError({ ...formError, phoneNumber: "" }); }}
                        value={phoneNumberWithMaking}
                        id="phone_number"

                      />
                      {formError?.phoneNumber && <span className="text-danger">{formError?.phoneNumber}</span>}
                    </Form.Group>


                  </Col>

                  <Col sm={12} md={12} lg={6} xl={6}>
                    <Form.Group className="mb-3" >
                      <Form.Label>Company Name *</Form.Label>
                      <Form.Control type="text"
                        name="companyName"
                        id="company_name"
                        value={formValues?.companyName}
                        onChange={(e) => { handleChange(e); setFormError({ ...formError, companyName: "" }); }}
                      />
                      {formError?.companyName && <span className="text-danger">{formError?.companyName}</span>}
                    </Form.Group>
                  </Col>

                  <Col sm={12} md={12} lg={6} xl={6}>
                    <Form.Group className="mb-3" >
                      <Form.Label>Business Type </Form.Label>
                      <Form.Select aria-label="Select Business Type" name='businessType' id="industry" value={formValues?.businessType} onChange={(e) => handleChange(e)}>
                        <option value="0">Select Business Type</option>
                        <option value="Real Estate Investor">Real Estate Investor</option>
                        <option value="Real Estate Wholesaling">Real Estate Wholesaling</option>
                        <option value="Real Estate Agent/Broker">Real Estate Agent/Broker</option>
                        <option value="Property Lending">Property Lending</option>
                        <option value="Property Vendor/Contractor">Property Vendor/Contractor</option>
                        <option value="Property Technology/Service">Property Technology/Service</option>
                        <option value="Title Company">Title Company</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>

                  <Col sm={12} md={12} lg={6} xl={6}>
                    <Form.Group className="mb-3" >
                      <Form.Label>Website </Form.Label>
                      <Form.Control
                        type="text"
                        name="website"
                        id="company_name"
                        placeholder='www.johndoellc.com'
                        onChange={handleChange}
                        value={formValues?.website}
                      />
                    </Form.Group>
                  </Col>

                  <Col sm={12} md={12} lg={6} xl={6}>
                    <Form.Group className="mb-3" >
                      <Form.Label>Social URL </Form.Label>
                      <Form.Control
                        type="text"
                        name="socialUrl"
                        id="company_name"
                        placeholder='www.instagram.com/johndoe'
                        onChange={handleChange}
                        value={formValues?.socialUrl}
                      />
                    </Form.Group>
                  </Col>

                </Row>
                {loader ? (
                  <Button type="button" disabled className="submit mt-4"><Spinner animation="border" size="sm" /> </Button>
                ) : (
                  <Button type="submit" className="submit mt-4">Submit </Button>
                )}


              </form>

            </div>
          </Col>
        </Row>
      </Container>

    </div>
  )
}

export default AffiliateForm