import React, { useEffect, useState } from 'react'
import AffiliateTop from './section/AffiliateTop'
import Highlights from './section/Highlights'
import HowShouldJoin from './section/HowShouldJoin'
import AffiliateRequiment from './section/AffiliateRequiment'
import AffiliateForm from './section/AffiliateForm'
import AffiliateFAQ from './section/AffiliateFAQ'
import { AFFILIATE_PAGE, StripeAxios } from '../../constants/strapi-api-constant'
import Seo from '../../components/Seo'
import Loader from '../../components/Loader'


const Affiliate = () => {
  const [loading, setLoading] = useState(false);
  const [content, setContent] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    getAffiliatePageContent()
  }, []);

  const getAffiliatePageContent = async () => {
    try {
      setLoading(true)
      const res = await StripeAxios.get(AFFILIATE_PAGE);
      if (res && res?.data) {
        setContent(res?.data)
      }
    } catch (error) {
      console.log("affiliate Err ====>", error);
    } finally {
      setLoading(false)
    }

  }
  return (
    <>
      {loading && <Loader />}
      <Seo metaData={content?.Seo} />
      <AffiliateTop content={content?.HeroSection} />
      <Highlights content={content?.AffiliateHighlightsSection} />
      <HowShouldJoin content={content?.AffiliateJoinSection} />
      <AffiliateRequiment content={content?.AffiliateRequirementsSection} />
      <AffiliateForm content={{ Heading: content?.FormHeading, Description: content?.FormDescription }} />
      <AffiliateFAQ content={content?.affiliate_faqs} Heading={content?.FaqsHeading} />
    </>
  )
}

export default Affiliate