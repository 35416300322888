import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { STRAPI_TERMS, StripeAxios, SEO_CONTENT_OF_TERMS } from '../../constants/strapi-api-constant';
import Loader from '../../components/Loader';
import Seo from '../../components/Seo';

const TermsCondition = () => {
  const [content, setContent] = useState(null);
  const [loading, setLoading] = useState(true);
  const [metaData, setMetaData] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = `Terms of Service - ${process.env.REACT_APP_NAME}`;

    const fetchData = async () => {
      try {
        const [termsResponse, seoResponse] = await Promise.allSettled([
          StripeAxios.get(STRAPI_TERMS),
          StripeAxios.get(SEO_CONTENT_OF_TERMS),
        ]);

        if (termsResponse.status === "fulfilled") {
          setContent(termsResponse.value?.data?.data || []);
        }

        if (seoResponse.status === "fulfilled") {
          setMetaData(seoResponse.value?.data?.data?.attributes?.Seo || {});
        }
      } catch (error) {
        console.error("API Error:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      {loading && <Loader />}
      <Seo metaData={metaData} />

      <header className="header-inner">
        <h1>Terms of Service Agreement</h1>
      </header>

      <div className="terms_and_condition">
        <Row className="pb-4">
          <Col sm={12} md={10} lg={8} className="mx-auto">
            {content?.map((item, i) => (
              <div className="term_box" key={i}>
                <h4>{`${i + 1}. ${item?.attributes?.Heading}`}</h4>
                {item?.attributes?.Description.map((des, d) => (
                  <React.Fragment key={d}>
                    {des?.type === "paragraph" ? (
                      <p>{des?.children?.[0]?.text}</p>
                    ) : (
                      <ul>
                        {des?.children?.map((list, l) => (
                          <li key={l}>{list?.children?.[0]?.text}</li>
                        ))}
                      </ul>
                    )}
                  </React.Fragment>
                ))}
              </div>
            ))}
          </Col>
        </Row>
      </div>
    </>
  );
};

export default TermsCondition;
