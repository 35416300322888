import React from 'react'
import { Row, Col, Button, Container } from 'react-bootstrap'
import { STRAPI_IMAGE } from '../../../constants/strapi-api-constant'
import { AiFillCheckSquare } from "react-icons/ai";


const HowShouldJoin = ({ content }) => {


  return (
    <>
      <div className='affiliate_should_join'>
        <Container>

          <Row>
            <Col xs={12} sm={12} md={12} lg={12} xl={6}>
              <div className='affiliate_should_text '>
                <h4 className='affiliate_highlights_text_head'>{content?.Heading}</h4>
                <p>{content?.Description}</p>
                <ul>
                  {content?.List?.map((list, l) => (
                    <li key={l}>
                      {!list?.Icon &&
                        <div className='icon'><AiFillCheckSquare /></div>
                      }
                      <div className='list_text'>
                        <h4 className='list_text_head'>{list?.Heading}</h4>
                        <p className='list_text_des'>{list?.Description}</p>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
              <a href='#affialte' className='btn-primary d-inline'> Apply Now</a>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={6} className='flexEnd'>
              <div className='radius_image_design shouldJoin' style={{ background: `url(${STRAPI_IMAGE + content?.Image?.url}` }}></div>
            </Col>
          </Row>

        </Container>

      </div>
    </>

  )
}

export default HowShouldJoin