import React from "react";
import { Col, Row } from "react-bootstrap";
import FeatureItem from "./FeatureItem";

const FeatureSection = ({ data }) => {
    if (!data) return null;

    const firstTwoFeatures = data.Features?.slice(0, 2) || [];
    const remainingFeatures = data.Features?.slice(2) || [];

    return (
        <section className="paddingSection">
            <Row>
                <Col md={12} xl={8} className="text-center mx-auto">
                    {data.Label && <span className="label-title">{data.Label}</span>}
                    {data.Heading && <h1 className="title my-4">{data.Heading}</h1>}
                </Col>
            </Row>

            <Row className="justify-content-between">
                <Col md={12} lg={6}>
                    {firstTwoFeatures.map((feature, index) => (
                        <FeatureItem key={index} feature={feature} />
                    ))}
                </Col>

                <Col md={12} lg={6}>
                    {remainingFeatures.map((feature, index) => (
                        <FeatureItem key={index + 2} feature={feature} />
                    ))}
                </Col>
            </Row>
        </section>
    );
};

export default FeatureSection;
