import React, { useEffect, useState } from 'react'
import Footer from "./Footer";
import Header from "./Header";
import { STRAPI_LOGO_CHANGES, StripeAxios } from '../constants/strapi-api-constant';
import FlashSaleModal from './FlashSaleModal';

const Layout = ({ children }) => {
  const [content, setContent] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    getHeaderContent()
  }, []);

  const getHeaderContent = async () => {
    try {
      const res = await StripeAxios.get(STRAPI_LOGO_CHANGES);
      if (res && res?.data) {
        setContent(res?.data)
      }
    } catch (error) {
      console.log("Header Err ====>", error);
    } finally {
    }

  }
  return (
    <div className='wrap'>
      <Header content={content} />
      <FlashSaleModal />
      <div>
        {children}
      </div>
      <Footer content={content} />
    </div>
  )
}

export default Layout