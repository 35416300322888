import React, { memo } from 'react'
import { Col, Image, Row } from 'react-bootstrap'
import { FiCheck } from 'react-icons/fi'
import { useNavigate } from 'react-router-dom';
import { STRAPI_IMAGE } from '../../../constants/strapi-api-constant';

const HeroSection = ({ data, handleShow, url }) => {
    const navigate = useNavigate();

    return (
        <div className="grid-img paddingSection">
            <Row className="justify-context-between py-4">
                <Col md={12} lg={6} className="align-self-center gradient-white">
                    <h1 className="heading test-head">{data?.Heading}</h1>
                    <div className="listing">
                        <ul>
                            {data?.Description.map((des, d) => {
                                const { type, children } = des;
                                if (type === "list") {
                                    return (
                                        <>
                                            {children?.map((item, li) => {
                                                const { children: itemChildren } = item;
                                                return (
                                                    <li key={li}>
                                                        <span className='check_icon'><FiCheck /></span>
                                                        <span>{itemChildren[0]?.text}</span>
                                                    </li>
                                                );
                                            })}
                                        </>
                                    );
                                }
                            })
                            }

                        </ul>
                    </div>
                    <div className="actions">
                        <Row className="justify-context-start">
                            <Col>
                                <button className="primary" onClick={() => navigate('/pricing-plan')}>Sign Up for Free</button>
                                <button className="secondary" onClick={() => handleShow(url)}>Watch Tutorial</button>
                            </Col>
                        </Row>
                    </div>
                </Col>

                <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                    <Image src={STRAPI_IMAGE + data?.Image?.url}
                        alt={data?.megaslideimage?.data?.attributes?.name}
                        loading="lazy"
                        width='600px'
                        height='500px'
                    />
                </Col>
            </Row>
        </div>
    )
}

export default memo(HeroSection)