import React from 'react'
import { Col, Row } from 'react-bootstrap'
import ReactPlayer from 'react-player'

const VideoSection = ({ data, getYouTubeThumbnail }) => {
    return (
        <Row className="paddingSection">
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <ReactPlayer height={"600"} className='mega-iframe' light={getYouTubeThumbnail(data?.VideoLink)} controls url={data?.VideoLink} playing={true} />
            </Col>
        </Row>
    )
}

export default VideoSection