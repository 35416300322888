import React from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { STRAPI_IMAGE } from '../../../constants/strapi-api-constant';
import { useNavigate } from 'react-router-dom';
import { Col, Container, Row } from 'react-bootstrap';

const LeadGeneration = ({ content }) => {
    const navigate = useNavigate();
    return (
        <Container className="feature paddingSection">
            <Row className="justify-content-center">
                <Col md={12} className="text-center">
                    <span className="label-title">{content?.HowToGetStarted?.Label}</span>
                </Col>
            </Row>
            <Row className="justify-content-center mt-4">
                <Col xs={12} lg={10}>
                    <h1 className="title mb-4">{content?.HowToGetStarted?.Heading}</h1>
                </Col>
            </Row>
            <Row className="justify-content-between">
                <Col md={6} lg={6} className="align-self-center">
                    <div className="grid-img height-maxi-img">
                        <LazyLoadImage
                            src={STRAPI_IMAGE + content?.HowToGetStarted?.Image?.url}
                            effect="blur"
                            className="maxim-img"
                            height={"150px"}
                            alt={content?.HowToGetStarted?.Image?.name} />
                    </div>
                </Col>
                <Col xs={12} md={6} lg={6} className=" align-self-center">
                    <ul className="maximize-list">
                        {content?.HowToGetStarted?.Description.map((des, d) => {
                            const { type, children } = des;
                            if (type === "list") {
                                return (
                                    <>
                                        {children?.map((item, inx) => {
                                            const { children: itemChildren } = item;
                                            return (
                                                <li key={inx}>
                                                    <span className="orange">{inx + 1}</span>
                                                    <p className="align-self-center">{item?.children[0]?.text}</p>
                                                </li>
                                            );
                                        })}
                                    </>
                                );
                            }
                        })
                        }
                    </ul>
                </Col>
            </Row>
            <Row className="justify-content-center mt-4 mb-4">
                <Col xs={12} md={12}>
                    <div className="actions">
                        <button className="primary center-btn-casual" onClick={() => navigate('/pricing-plan')}>Get Started Today</button>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}

export default LeadGeneration