import React from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { STRAPI_IMAGE } from '../constants/strapi-api-constant'
import { Col, Row } from 'react-bootstrap'
import { useLocation } from 'react-router-dom'


const FeatureItem = ({ feature }) => {
    const location = useLocation()
    const ImagePath = location?.pathname == '/' ? feature?.Image?.url : feature?.Image?.data?.attributes?.url;
    return (
        <>
            {feature?.DesignType === "DivTop" ?
                <div className="box-feature">
                    <Row className="justify-context-between">
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} >
                            <h1>{feature?.Heading}</h1>
                            <LazyLoadImage
                                src={STRAPI_IMAGE + ImagePath}
                                effect="blur"
                                alt={feature?.Image?.data?.attributes?.name}
                                className={`rounded-4 ${location?.pathname == '/' ? '' : 'height-spec'}`}
                            />
                            <p className="mt-2 mb-0">{feature?.Description}</p>
                        </Col>
                    </Row>
                </div>
                : feature?.DesignType === "DivLeft" ?
                    <div className="box-feature">
                        <Row className="justify-context-between">
                            <Col xs={12} sm={12} md={6} lg={4} xl={4} className="align-self-center">
                                <LazyLoadImage
                                    src={STRAPI_IMAGE + ImagePath}
                                    effect="blur"
                                    className='rounded-4'
                                    alt={feature?.Image?.data?.attributes?.name}
                                />
                            </Col>
                            <Col xs={12} sm={12} md={6} lg={8} xl={8} className="align-self-center">
                                <h1 className='mb-4'>{feature?.Heading}</h1>
                                <p>{feature?.Description}</p>
                            </Col>
                        </Row>
                    </div>
                    : feature?.DesignType === "DivRight" ?
                        <div className="box-feature ">
                            <Row >
                                <Col xs={12} sm={12} md={6} lg={8} xl={8} className="align-self-center">
                                    <h1 className="my-md-2">{feature?.Heading}</h1>
                                    <p className='wd-92'>{feature?.Description}</p>
                                </Col>
                                <Col xs={12} sm={12} md={6} lg={4} xl={4}>
                                    <LazyLoadImage
                                        src={STRAPI_IMAGE + ImagePath}
                                        effect="blur"
                                        className='rounded-4'
                                        alt={feature?.Image?.data?.attributes?.name}
                                    />
                                </Col>
                            </Row>
                        </div>
                        : feature?.DesignType === "DivRightLarge" ?
                            <div className="box-feature ">
                                <div className="row">
                                    <Col xs={12} sm={12} md={6} lg={6} xl={6} className='middle-content-center'>
                                        <h1 className="mb-4 mt-3">{feature?.Heading}</h1>
                                        <p>{feature?.Description}</p>
                                    </Col>
                                    <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                        <LazyLoadImage
                                            src={STRAPI_IMAGE + ImagePath}
                                            effect="blur"
                                            className='rounded-4 img-fluid'
                                            alt={feature?.Image?.data?.attributes?.name}
                                        />
                                    </Col>
                                </div>
                            </div>
                            : ''

            }

        </>
    )
}

export default FeatureItem