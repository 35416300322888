import React from 'react'
import { AiFillCheckSquare } from "react-icons/ai";
import { Row, Col, Container } from 'react-bootstrap'
import { STRAPI_IMAGE } from '../../../constants/strapi-api-constant'

const Highlights = ({ content }) => {



  return (
    <>
      <div className='affiliateHighlights'>
        <Container>
          <Row>
            <Col xs={{ span: 12, order: 2 }} sm={{ span: 12, order: 2 }} md={{ span: 12, order: 2 }} lg={{ span: 12, order: 2 }} xl={{ span: 5, order: 1 }} className='flexCenter'>
              <div className='affiliate_highlights_image' style={{ background: `url(${STRAPI_IMAGE + content?.Image?.url}`, backgroundSize: "cover", backgroundPosition: 'center', backgroundRepeat: "no-repeat" }}></div>
            </Col>
            <Col xs={{ span: 12, order: 1 }} sm={{ span: 12, order: 1 }} md={{ span: 12, order: 1 }} lg={{ span: 12, order: 1 }} xl={{ span: 7, order: 2 }}>
              <div className='affiliate_highlights_text'>
                <h3 className='affiliate_highlights_text_head'>{content?.Heading}</h3>
                <ul>
                  {content?.List?.map((list, l) => (
                    <li key={l}>
                      {!list?.Icon &&
                        <div className='icon'><AiFillCheckSquare /></div>
                      }
                      <div className='list_text'>
                        <h4 className='list_text_head'>{list?.Heading}</h4>
                        <p className='list_text_des'>{list?.Description}</p>
                      </div>
                    </li>
                  ))}


                </ul>

              </div>
              <Row>
                <Col xs={10} sm={12} md={12} lg={11} xl={10} className='mx-auto'>
                  <a href='#affialte' className='btn-primary d-inline mb-4'> Apply Now</a>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>

      </div >
    </>

  )
}

export default Highlights