import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import NewHomeBlogItem from "../../../components/NewHomeBlogItem";
import { STRAPI_API_HOST, StripeAxios } from "../../../constants/strapi-api-constant";

const BlogSection = ({ content }) => {

    const [blogs, setBlogs] = useState([]);

    useEffect(() => {
        fetchBlogs();
    }, []);

    const fetchBlogs = async () => {
        try {
            const apiUrl = `${STRAPI_API_HOST}/blogs?populate=*&pagination[page]=1&pagination[pageSize]=3&sort[0]=publishedAt:desc`;
            const response = await StripeAxios.get(apiUrl);
            if (response?.data?.data) {
                setBlogs(response.data.data);
            }
        } catch (error) {
            console.error("Error fetching blog updates:", error);
        }
    };

    return (
        <section className="paddingSection">
            <Row className="justify-content-center">
                <Col className="text-center">
                    <span className="label-title">{content?.BlogSectionLabel}</span>
                </Col>
            </Row>
            <Row className="justify-content-center mt-4">
                <Col md={12}>
                    <h1 className="title mb-4">{content?.BlogSectionHeading}</h1>
                </Col>
            </Row>
            <Row className="justify-content-center blogs-ai">
                {blogs.length > 0 ? (
                    blogs.map((blog, index) => (
                        <Col xs={12} md={6} lg={4} key={blog.id || index}>
                            <NewHomeBlogItem blog={blog} />
                        </Col>
                    ))
                ) : (
                    <Col className="text-center">
                        <p>No blogs available at the moment.</p>
                    </Col>
                )}
            </Row>
        </section>
    );
};

export default BlogSection;
