import React, { useState } from 'react'
import { BiBadgeCheck } from "react-icons/bi";
import { Col, Modal, Row, Button } from 'react-bootstrap'
import { AiOutlineClose } from "react-icons/ai";
import { useNavigate } from 'react-router-dom';

const FlashSaleModal = () => {
    const [show, setShow] = useState(true);
    const [success, setSuccess] = useState(false)
    const history = useNavigate();

    const handleRedirect = () => {
        setSuccess(true)
        setTimeout(() => {
            setShow(false)
            history('/pricing-plan')
        }, 3000);
    }

    return (
        <Modal
            size="lg"
            show={show}
            centered
            className='flashModal'
        >
            <Modal.Body className='p-0'>
                {success ?
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={6} xl={8} className='mx-auto'>
                            <div className="w-100">
                                <button className='closeBtn' onClick={() => setShow(false)}><AiOutlineClose /></button>
                            </div>
                            <div className='flashText secondDiv'>
                                <BiBadgeCheck />
                                <h4 className='text-center mt-3'>Your bonus skips will be added to your account within 48 hours of your purchase. Thank you!</h4>
                            </div>
                        </Col>
                    </Row>

                    :
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={6} xl={6} className='p-0 backgroundImageSplash'></Col>
                        <Col xs={12} sm={12} md={12} lg={6} xl={6} className="d-flex justify-content-start align-items-center flex-column">
                            <div className="w-100">
                                <button className='closeBtn' onClick={() => setShow(false)}><AiOutlineClose /></button>
                            </div>
                            <div className='flashText'>
                                <h4>All New Subscriptions and Upgrades </h4>
                                <h1> <span>2X</span> Your Skips!</h1>
                                <h4 className='mb-3'>One-time limited offer</h4>
                                <Button variant='primary' onClick={() => handleRedirect()}>Earn 2X Skips Now</Button>
                                <p>Disclaimer: Offer is only valid for new subscriptions and subscription upgrades only until April 7th, 2025. One-time bonus skips apply for the first month and do not recur. Not applicable to ad hoc purchases. Additional terms and conditions apply. Your bonus skips will be added to your account within 48 hours of your purchase.</p>
                            </div>
                        </Col>
                    </Row>
                }

            </Modal.Body>

        </Modal>
    )
}

export default FlashSaleModal