import React from 'react'
import { Row, Col, Container } from 'react-bootstrap'
import { STRAPI_IMAGE } from '../../../constants/strapi-api-constant';

const AffiliateTop = ({ content }) => {
  const line = content?.Subheading?.split(' - ');

  return (
    <>
      <div className='affiliateTop'>
        <Container>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12} xl={7}>
              <div className='affiliate_top_text'>
                <h1>{content?.Heading}</h1>
                {line?.map((Ln, n) => (
                  <h3 key={n}>{Ln}</h3>
                ))}
                <p>{content?.Description[0]?.children[0]?.text}</p>
              </div>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={5} className='flexCenter'>
              <div className='affiliate_top_image' style={{ background: `url(${STRAPI_IMAGE + content?.Image?.url}`, backgroundSize: "cover", backgroundPosition: 'center' }}></div>
            </Col>
          </Row>
        </Container>
      </div>
    </>

  )
}

export default AffiliateTop