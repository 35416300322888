import React, { useEffect, useState } from 'react'
import { Col, Container, Image, Row, } from 'react-bootstrap'
import { STRAPI_ABOUT_TOP, StripeAxios, STRAPI_IMAGE } from '../../../constants/strapi-api-constant'
import Loader from '../../../components/Loader'
import placeholder from './../../../assets/images/placeholder.png';

const TopAboutSection = () => {

    useEffect(() => {
        getPageContent();
    }, []);

    const [content, setContent] = useState({});
    const [loading, setLoading] = useState(false)
    const getPageContent = async () => {
        try {

            await StripeAxios.get(STRAPI_ABOUT_TOP)
                .then(res => {
                    setLoading(false)
                    const { data } = res.data;
                    setContent(data.attributes || {});
                })
                .catch(err => { setLoading(false); console.log("about top", err) });

        } catch (error) {
            setLoading(false)
            console.log("about top error", error)
        }
    }
    return (
        <div className='about_page_top_section' id="about">
            {loading && <Loader />}
            <Container className='my-5'>

                <Row>
                    <Col md={12} lg={6}>
                        <div className='about_us_text'>
                            <h1>{content?.Title}</h1>
                            <h4>{content?.SubTitle}</h4>
                            <p>{content?.Description}</p>
                        </div>
                    </Col>
                    <Col md={8} className='mx-auto' lg={{ span: 5, offset: 1 }}>
                        {content?.Image ?
                            <Image src={STRAPI_IMAGE + content?.Image?.data?.attributes?.url} alt={STRAPI_IMAGE + content?.Image?.data?.attributes?.name} className='img-fluid' />
                            :
                            <Image src={placeholder} alt='' className='img-fluid' />
                        }

                    </Col>
                </Row>
                <Row>
                    <Col md={11} className='mx-auto mt-5'>
                        <div className='subscribe_section'>
                            <Row>
                                <Col md={10} lg={8} className='mx-auto'>
                                    <h2>Sign up for a FREE account!</h2>
                                    <p>Receive 500 free property records.</p>
                                    <p> No credit card required.</p>
                                    <Row>
                                        <Col xs={12} sm={12} md={4} lg={6} className='mx-auto'><a href="/pricing-plan" className='main btn btn-primary w-100 my-2'>Create Free Account</a></Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default TopAboutSection