import React from 'react'
import { handleViewDescription } from '../../../utils/CommonView'
import { Col, Row } from 'react-bootstrap'

const WhyWeStand = ({ content }) => {
    return (
        <div className="optimize-cont">
            <Row className="justify-content-center">
                <Col ms={12} className="text-left mb-4">
                    <span className="label-title">{content?.StandOutLabel}</span>
                </Col>
            </Row>
            <Row className="justify-content-between optimize-sec">
                <Col xs={12} md={12} lg={7} className="align-self-center">
                    <h1 className="left-sec">
                        {content?.StandOutHeading?.length > 0 &&
                            <>
                                <span>{content?.StandOutHeading[0]?.children[0]?.text}</span>
                                {content?.StandOutHeading[0]?.children[1]?.text}
                            </>
                        }

                    </h1>
                </Col>
                <Col xs={12} md={12} lg={5} className="align-self-center right-sec">
                    {handleViewDescription(content?.StandOutDescription)}
                </Col>

                <hr />

                <Row className="category-opt justify-content-center">
                    {content?.StatisticItems?.map((list, idx) => (
                        <Col xs={12} md={6} lg={4} className=" box-category" key={idx}>
                            <h1>{list.heading}</h1>
                            <p>{list.description}</p>
                        </Col>
                    ))}

                </Row>
            </Row>

        </div>
    )
}

export default WhyWeStand