import { BsArrowLeft } from "react-icons/bs";
import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useLocation, useNavigate } from 'react-router-dom'
import { Player, BigPlayButton } from 'video-react';
import { STRAPI_IMAGE } from "../../constants/strapi-api-constant";
import ReactPlayer from 'react-player'
import Seo from "../../components/Seo";

const SkipifyDetail = () => {

  const history = useNavigate();
  const { state } = useLocation();

  const video = state?.vedio;
  const [metaData] = useState(video?.attributes?.Seo || {});

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Seo metaData={metaData} />
      <div className='terms_and_condition2'>

        <div className=''>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12} xl={10} className='mx-auto'>
              <Row>
                <Col md={2}>

                  <BsArrowLeft onClick={() => history('/skipify-u')} className="back" />
                </Col>
                <Col md={8}>

                  {video ?
                    <h3 className="text-center text-white">{video.attributes.Title}</h3>
                    :
                    <h3 className="text-center text-white">HOW TO SET UP YOUR SKIPIFY ACCOUNT</h3>
                  }

                </Col>
              </Row>
              <div className='term_box2'>
                <Row className='d-flex justify-content-between my-5'>
                  <Col xs={12} sm={12} md={12} lg={8} xl={8} className="mx-auto vediodiv">
                    {video?.attributes?.Video?.data != null ?
                      <Player
                        src={video?.attributes?.url ? video?.attributes?.url : STRAPI_IMAGE + video?.attributes?.Video?.data?.attributes?.url}
                        poster={STRAPI_IMAGE + video?.attributes?.Thumbnail?.data?.attributes?.url}
                      >
                        <BigPlayButton position="center" />
                      </Player>
                      :
                      ''
                    }
                    {video?.attributes?.Video?.data == null ?
                      <ReactPlayer className='react-player' url={video?.attributes?.url} />
                      : ''

                    }
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      </div>

    </>
  )
}

export default SkipifyDetail