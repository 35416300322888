import { BsFillQuestionCircleFill } from "react-icons/bs";
import React from 'react'
import { Col, Container, Row, Accordion } from 'react-bootstrap'

const AffiliateFAQ = ({ content, Heading }) => {


  return (
    <>
      <div className='affiliateFAQ skybg'>
        <Container>
          <Row>
            <Col xs={12} sm={12} md={11} lg={12} xl={9} className='mx-auto'>
              <h1>{Heading}</h1>

              <Row>
                <Col md={12}>
                  <Accordion defaultActiveKey="0">
                    {content?.map((faq, f) => (
                      <Accordion.Item eventKey={f} key={f}>
                        <Accordion.Header>
                          <div className="icon_faq">
                            <BsFillQuestionCircleFill />
                          </div>
                          {faq?.Questions}
                        </Accordion.Header>
                        <Accordion.Body>{faq?.Answer}</Accordion.Body>
                      </Accordion.Item>
                    ))}
                  </Accordion>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </>

  )
}

export default AffiliateFAQ