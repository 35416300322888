import React from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { STRAPI_IMAGE } from '../../../constants/strapi-api-constant'
import { useNavigate } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
const MiniSection = ({ content, handleShow }) => {

    const navigate = useNavigate();

    return (
        <Row className="justify-content-between property-chart mb-5">
            <Col xs={12} lg={6} className="mb-md-4 align-self-center">
                <h1 className="mb-4">{content?.DynamicDataBoost?.Heading}</h1>
                <div className="actions ">
                    <Row className="justify-context-start">
                        <Col md={12}>
                            <button className="secondary light-yellow-theme" onClick={() => navigate('/pricing-plan')}>Sign Up for Free</button>
                            <button className="secondary" onClick={() => handleShow(content?.DynamicDataBoost?.VideoLink)}>Watch Tutorial</button>
                        </Col>
                    </Row>
                </div>

            </Col>
            <Col md={12} lg={6} className="align-self-center">
                <LazyLoadImage
                    src={STRAPI_IMAGE + content?.DynamicDataBoost?.VideoMedia?.url}
                    effect="blur"
                    className="prop-chart"
                    height='411'
                    width='572'
                    alt={content?.DynamicDataBoost?.VideoMedia?.name}
                />
            </Col>
        </Row>
    )
}

export default MiniSection