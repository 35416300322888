import React, { useEffect, useState } from 'react'
import { Col, Container, Row, Image } from 'react-bootstrap'
import { STRAPI_MISSION_AND_VISION, StripeAxios, STRAPI_IMAGE } from '../../../constants/strapi-api-constant';
import Loader from '../../../components/Loader';

import placeholder from './../../../assets/images/placeholder_v.png';

const MissionVision = () => {
    const [content, setContent] = useState({});
    const [loading, setLoading] = useState(false)
    useEffect(() => {
        getPageContent()
    }, [])


    const getPageContent = async () => {
        try {
            setLoading(true)
            await StripeAxios.get(STRAPI_MISSION_AND_VISION).then(res => {
                setLoading(false)
                const { data } = res?.data;
                setContent(data);
            })
                .catch(err => { setLoading(false); console.log("Mission and vision", err) })
        } catch (error) {
            setLoading(false)
            console.log("Mission and vision", error)
        }
    }
    return (
        <div className='mission_vision'>
            {loading && <Loader />}
            <Container>
                <Row>
                    <Col md={8} className='mx-auto'>
                        <h1>Providing Real Data To Real Estate Professionals</h1>
                    </Col>
                </Row>

                <Row>
                    <Col xs={{ span: 12, order: 2 }} sm={{ span: 12, order: 2 }} md={{ span: 12, order: 2 }} lg={{ span: 6, order: 1 }} className='flex_div'>
                        <div className='mession_vision_text'>
                            <h2>{content?.attributes?.MissionTitle ?? ''}</h2>
                            <p>{content?.attributes?.MissionDescription[0].children[0].text ?? ''}</p>
                        </div>

                        <div className='mession_vision_text'>
                            <h2>{content?.attributes?.VissionTitle ?? ''}</h2>
                            <p>{content?.attributes?.VisionDescription[0].children[0].text ?? ''}</p>
                        </div>
                    </Col>
                    <Col xs={{ span: 12, order: 1 }} sm={{ span: 12, order: 1 }} md={{ span: 8, order: 1 }} className='mx-auto' lg={{ span: 6, order: 2 }} >
                        <div className='img_div_vision'>
                            {content?.attributes?.Image ?

                                <Image src={STRAPI_IMAGE + content?.attributes?.Image?.data[0]?.attributes?.url} alt="vision" />
                                :

                                <Image src={placeholder} alt='' className='img-fluid' />
                            }
                        </div>
                    </Col>
                </Row>

            </Container>
        </div>
    )
}

export default MissionVision