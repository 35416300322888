import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { LazyLoadImage } from "react-lazy-load-image-component";
import { STRAPI_IMAGE } from '../../../constants/strapi-api-constant';

const SurplusFunds = ({ data }) => {
    const [lists, setLists] = useState([]);
    const [heading, setHeading] = useState();
    const [listPara, setListPara] = useState([]);
    const [listHead, setListHead] = useState([]);

    useEffect(() => {
        data?.Description?.map((item) => {
            if (item?.type === "list") {
                setLists(item?.children);
            } else if (item?.type === "heading") {
                setHeading(item?.children?.length > 0 ? item?.children[0].text : '');
            }
        })
        data?.Paragraph2?.map((item, i) => {
            if (item?.type === "list") {
                setListPara(item?.children);
            } else {
                setListHead(item?.children[0].text);
            }
        })
    }, [data]);


    return (
        <>
            <Container className="trust-by gray-theme">
                <Row className="justify-context-center mb-4">
                    <Col md={12} lg={5} className="align-self-center">
                        <h1 className='text-gray800'>{data?.Heading}</h1>
                    </Col>
                    <Col md={12} lg={7} className="bd-l-grey ps-lg-4">
                        <h2 className='mb-3 li-sub-process'>
                            {data?.Paragraph2 ?
                                <>
                                    {listHead}
                                    <ul>
                                        {listPara.map((list, l) => <>
                                            <li><b>{list?.children[0]?.text}</b>
                                                {list?.children[1]?.text}</li>
                                        </>)}
                                    </ul>

                                </> : <>{data?.Paragraph}</>}
                        </h2>
                    </Col>
                </Row>

                <Row className="justify-context-center py-4">
                    {data?.SectionType === "LeftToRight" ?
                        <>
                            <Col md={12} xl={5} className=" align-self-center">
                                <h4 className='description mb-4'>{heading}</h4>
                                <ul className="maximize-list">
                                    {lists.map((list, l) => <>
                                        <li key={l}>

                                            {list?.children?.length > 1 ?

                                                <>
                                                    <div className='col-cent'>
                                                        <span className="orange">{l + 1}</span>
                                                    </div>
                                                    <div className='maximize-list-div'>
                                                        <h6 >{list?.children[0]?.text}</h6>
                                                        <div
                                                            className='innerDiv'
                                                            dangerouslySetInnerHTML={{ __html: list?.children[1]?.text }}
                                                        />
                                                    </div>
                                                </>
                                                :
                                                <>
                                                    <div className='col-cent'>
                                                        <span className="orange">{l + 1}</span>
                                                    </div>
                                                    <div className='maximize-list-div'>
                                                        <h6 >{list?.children[0]?.text}</h6>

                                                    </div>
                                                </>

                                            }
                                        </li>
                                    </>)}
                                </ul>
                            </Col>
                            <Col md={12} xl={7} className="align-self-center">
                                <LazyLoadImage
                                    src={STRAPI_IMAGE + data?.Image?.data?.attributes?.url}
                                    effect="blur"
                                    className='img-fluid pd-l-8'
                                    alt="Foreclosure Data"
                                />
                            </Col>
                        </>
                        :
                        <>
                            <Col xl={12}>
                                <h4 className='text-dark text-gray800 mb-4'>{heading}</h4>
                            </Col>
                            <Col className="align-self-center" md={12} xl={5}>
                                <Row>
                                    <Col>
                                        <LazyLoadImage
                                            src={STRAPI_IMAGE + data?.Image?.data?.attributes?.url}
                                            effect="blur"
                                            className='img-fluid pd-l-8'
                                            alt="Foreclosure Data"
                                        />
                                    </Col>
                                </Row>
                            </Col>

                            <Col md={12} xl={7} className="align-self-center">
                                <ul className="maximize-list">
                                    {lists.map((list, l) => <>
                                        <li key={l}>

                                            {list?.children?.length > 1 ?
                                                <>
                                                    <div className='col-cent'>
                                                        <span className="orange">{l + 1}</span>
                                                    </div>
                                                    <div className='maximize-list-div'>
                                                        <h6 >{list?.children[0]?.text}</h6>
                                                        <div
                                                            className='innerDiv'
                                                            dangerouslySetInnerHTML={{ __html: list?.children[1]?.text }}
                                                        />
                                                    </div>
                                                </>
                                                :
                                                <>
                                                    <div className='col-cent'>
                                                        <span className="orange">{l + 1}</span>
                                                    </div>
                                                    <div className='maximize-list-div'>
                                                        <h6 >{list?.children[0]?.text}</h6>
                                                    </div>
                                                </>
                                            }
                                        </li>
                                    </>)}
                                </ul>
                            </Col>
                        </>
                    }


                </Row>
            </Container>

        </>
    )
}

export default SurplusFunds