import React from 'react'
import { STRAPI_IMAGE } from '../../../constants/strapi-api-constant'
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useNavigate } from 'react-router-dom';
import { Button, Col, Row } from 'react-bootstrap';

const HeroSection = ({ data, handleShow }) => {
    const navigate = useNavigate();
    const ImgUrl = data?.Image.data && data?.Image?.data?.attributes?.url;
    return (
        <>
            <div className="grid-img mb-4">
                <Row className="row justify-content-between py-4">
                    <Col xs={12} md={12} lg={5} xl={5} className="align-self-center">
                        <h1 className="heading">{data?.Heading ?? ''}</h1>
                        <div className="listing">
                            {data?.Description[0]?.type === "paragraph" ? <p>{data?.Description[0]?.children[0].text}</p> : ''}
                        </div>
                        <Row className="justify-context-start">
                            <Col xs={12} md={6} lg={6} xl={5} >
                                <Button className="primary w-100" onClick={() => navigate('/pricing-plan')}>Sign Up for Free</Button>
                            </Col>
                            <Col xs={12} md={6} lg={6} xl={6} >
                                <Button className="outline-theme  mb-3" onClick={() => handleShow(1)}>Watch Tutorial</Button>
                            </Col>
                        </Row>
                    </Col>

                    <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                        <LazyLoadImage
                            src={STRAPI_IMAGE + ImgUrl}
                            effect="blur"
                            className='img-fluid'
                            alt="hero Image"
                        />
                    </Col>
                </Row>
            </div>

        </>
    )
}

export default HeroSection;