import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'

const Subscription = ({ data }) => {
    return (
        <Container className="newsletter my-5">
            <Row className="justify-content-center">
                <Col md={12} lg={8} className="align-self-center ">
                    <h4 className='text-gray800 mb-2'>{data?.Heading}</h4>
                    <p className='subText'>{data?.Description}</p>
                </Col>

                <Col xs={12} md={12} lg={2} className='align-self-center'>
                    <div className="actions">
                        <Link to="/pricing-plan" className=" btn button-anchor wd-100 mt-3">Get Started</Link>
                    </div>
                </Col>

            </Row>
        </Container>
    )
}

export default Subscription