import React from 'react'
import { STRAPI_IMAGE } from '../constants/strapi-api-constant'
import { Card, Button } from 'react-bootstrap'
import { useNavigate, useLocation, Link } from 'react-router-dom'
import { LazyLoadImage } from "react-lazy-load-image-component";
import placeholder from './../assets/images/placeholder_v.png';
const BlogItem = ({ blog }) => {
  const history = useNavigate()
  const { pathname } = useLocation();
  const handleRedirect = () => {
    history(`/blog/${blog?.attributes?.slug}`)
  }
  return (
    <Link to={`/blog/${blog?.attributes?.slug}`}>
      <Card className='blog-card' onClick={() => handleRedirect()}>
        {blog?.attributes?.Image ?
          <LazyLoadImage
            src={STRAPI_IMAGE + blog?.attributes?.Image?.data?.attributes?.url}
            PlaceholderSrc={placeholder}
            effect="blur"
            className='img-fluid'
            alt={blog?.attributes?.ImageAlt}
          />
          :
          ''
        }
        <Card.Body>
          <Card.Title>{blog.attributes.Category}</Card.Title>
          <h5>{blog.attributes?.Title}</h5>
          {pathname !== '/blogs' ? <Button variant="default" type='button' onClick={handleRedirect}>Read More</Button> : ''}
        </Card.Body>
      </Card>
    </Link>

  )
}

export default BlogItem