import React from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { STRAPI_IMAGE } from '../constants/strapi-api-constant'
import { HiUser } from 'react-icons/hi'

const TestimonialItem = ({ testimonial }) => {
    return (
        <div className="box-category">
            <h6 className="height-5">{testimonial.Description}</h6>
            <div className="feedback-auth">
                <div className="imgDiv">
                    {testimonial?.Profile?.data ?
                        <LazyLoadImage
                            src={STRAPI_IMAGE + testimonial?.Profile?.data?.attributes?.url}
                            alt={testimonial?.Profile?.data?.attributes?.name}
                        />
                        :
                        <HiUser />
                    }
                </div>
                <div className="box">
                    <h6>{testimonial.Author}</h6>
                    <p>{testimonial.Designation}</p>
                </div>
            </div>
        </div>
    )
}

export default TestimonialItem